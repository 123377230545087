import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

// breadcrumbs
const breadCrumbLevels = {
  Hjem: "/",
  "Analytics Consulting": "/da/analyse-konsulent",
  "Google Analytics Opsætning": "/da/google-analytics-opsætning"
};

// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/google-analytics-setup"
);

const AnalyticsSetup = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Opsæt Google Analytics fra bunden! [${currentYear} Guide]`}
        description="Guide til, hvordan man konfigurerer en moderne Google Analytics opsætning. Opsætning af mål, event tracking og bedste praksis. Tutorial fra certificeret Google Analytics konsulent."
        lang="da"
        image="google-analytics-setup-realtime-report-hero"
        alternateLangs={alternateLangs}
        canonical="/da/google-analytics-opsætning"
      />
      <MainContent article>
        <ImgScreenshot
          src="google-analytics-einrichten/google-analytics-setup-realtime-report-hero.png"
          alt="mand arbejder på Google Analytics opsætning med bærbar computer"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Google Analytics Setup Guide</H>
        <p>
          En af de første opgaver for en ny hjemmeside er at opsætte Google Analytics. Efter al den tid og
          indsats du har investeret i at bygge en hjemmeside, vil du selvfølgelig måle og analysere brugernes adfærd. Du
          kan have spørgsmål som:
        </p>

        <ul>
          <li>Hvordan præsterer mit indhold?</li>
          <li>Hvilken produktkategori er den mest populære?</li>
          <li>Hvor mange konverteringer udløste min Facebook-kampagne?</li>
          <li>Ser besøgende faktisk den video, jeg har produceret?</li>
          <li>Er der nogen sider, der præsterer særligt dårligt?</li>
          <li>Deler besøgende noget på sociale medier?</li>
          <li>Forlader folk under betalingsprocessen?</li>
        </ul>

        <p>
          Alle antagelser om brugeradfærd samt kampagnepræstationer skal valideres. <a href="https://w3techs.com/technologies/details/ta-googleanalytics" target="_blank">55% af alle hjemmesider på internettet bruger Google Analytics</a> til det. Faktisk har det en <a href="https://w3techs.com/technologies/overview/traffic_analysis" target="_blank">markedsandel på 84% på markedet for trafikanalysværktøjer</a>, så du behøver ikke bekymre dig om, at du vælger det forkerte analytiske værktøj.
        </p>
        <p>
          Et webanalyserværktøj gør det muligt for dig at træffe beslutninger baseret på data i stedet for gætterier. Sådanne beslutninger kan for eksempel handle om, hvilken type indhold der skal investeres i, eller hvor meget budget der skal bruges på annoncekampagner.
        </p>
        <p>Derfor er det essentielt at opsætte Google Analytics professionelt, så du sporer de rigtige KPI'er til din analyse. Med en sådan opsætning vil du have masser af metrics til rådighed til at dykke ned i.
        </p>
        <H as="h2">Typer af Google Analytics opsætninger</H>
        <p>
          Typen af hjemmeside er en nøglefaktor for en Google Analytics opsætning:
          <br />
          Driver du en online butik eller sælger produkter? <br />
          Så er {/* <Link to="/da/ecommerce-analytics"> */} e-commerce analytics {/* </Link> */} vejen frem. Det er mere produkt- og transaktionsorienteret og vil
          give dig nyttige indsigter, som du ikke vil gå glip af.
        </p>
        <p>
          Hvis du driver en indholdsorienteret side om dit brand, så er en typisk Google Analytics sporingsopsætning den rette. <br />
          Du kan spore brugerinteraktioner med <Link to="/da/event-sporing">event tracking</Link> og overvåge
          vigtige hjemmeside-KPI'er med{" "}
          <Link to="/da/google-analytics-opsætning">Google Analytics mål</Link>.
        </p>

        <p>
          E-commerce analytics opsætninger kræver mere data om produkter og transaktioner. Derfor er konfigurationen lidt mere kompleks og involverer brugerdefineret JavaScript til at opbygge et såkaldt{" "}
          <Link to="/da/data-lag">data layer</Link>
          . <br />Et data layer er essentielt et datalager, der holder information om hver side. Google
          Analytics vil derefter genkende og bruge det, når det er tilgængeligt.
        </p>
        <p>
          Vær ikke bekymret, hvis du indser, at du skal opsætte Google Analytics for en e-commerce side. Du kan
          aktivere e-commerce funktionalitet senere og også forbedre data layer, når du er klar til det.{" "}
          <br />
          Indtil da vil e-commerce rapporter forblive tomme, men andre rapporter vil fungere fint.
        </p>

        <H as="h2">Hvor får man Google Analytics sporingskoden?</H>
        <p>
          Besøg{" "}
          <a href="https://analytics.google.com" target="_blank" rel="noopener">
            analytics.google.com
          </a>{" "}
          og klik på <strong>Signup</strong> knappen for at oprette en Google Analytics konto. Du vil få Google
          Analytics sporingskoden, når du følger nedenstående trin.
        </p>
        <ImgScreenshot
          src="google-analytics-setup/singup-for-google-analytics-tracking-code.png"
          alt="Google Analytics tilmeldingsskærmbillede"
          className="article-img"
        />
        <ul>
          <li>
            Efter du klikker på <strong>signup</strong> knappen, klik videre og vælg et ejendomsnavn og URL
            efterfulgt af din branche og tidszone.{" "}
          </li>
          <li>
            Jeg anbefaler <strong>at fravælge alle datadelingsmuligheder</strong>, hvis du ikke er helt på toppen af din
            privatlivspolitik. Men det betyder, at Google <strong>ikke</strong> vil dele demografiske data og interesse
            rapporter med dig. <br />
            Hvis du vil bruge disse rapporter, skal du muligvis opdatere din privatlivspolitik. Dette er for at informere brugerne om, at du deler data om deres browsingadfærd med Google.
          </li>
          <li>
            Når du er færdig, modtager du dit <strong>Google Analytics sporings-ID</strong> (f.eks.{" "}
            <em>UA-141112345-1</em>). Sammen med det, vil du modtage kode snippet for at indsætte i kildekoden på
            alle dine sider.
          </li>
        </ul>
        <H as="h2">Hvordan implementeres Google Analytics?</H>
        <p>Der er generelt to måder at implementere en Google Analytics på:</p>

        <ol>
          <li>
            Du indsætter Google Analytics sporingskoden i kildekoden på alle sider. Du kan gøre dette side for side eller indsætte det i en fælles skabelonfil. Det kan være header, navigation eller footer, som alle sider har til fælles.
            <br />
            <strong>Jeg anbefaler ikke</strong> denne måde at implementere Google Analytics på, medmindre du vil gøre det
            hurtigt og nemt og aldrig røre ved analyseopsætningen igen. <br />
            De fleste sporingsopsætninger gøres hurtigere og nemmere med et <Link to="/da/tag-styring">tag management system</Link>. Det er en lettere og også mere fleksibel proces, så det er værd at introducere endnu <em>et værktøj</em> til stakken.
          </li>

          <li>
            Du bruger et tag management system, som Google Tag Manager, som er ret populært.
            <br />
            Der er andre alternativer som <em>Adobe Launch</em>, <em>Tealium</em>, <em>Ensighten</em> eller{" "}
            <em>Matomo</em> (open source) også. <br />
            Generelt tjener de alle det samme formål og er meget ens. Du kan implementere Google Analytics med et
            ikke-Google tag management system uden bekymring. Google Tag Manager er bare den, som alle nemt kan få adgang til og har flest ressourcer tilgængelige online.
            <br />
            Hvis du vil lære mere om det, så tjek min <Link to="/da/google-tag-manager-opsætning">Google Tag Manager tutorial</Link>.
          </li>
        </ol>

        <H as="h2">Hvordan implementeres Google Analytics med Google Tag Manager?</H>
        <p>
          For <strong>implementeringen af Google Analytics via Google Tag Manager,</strong> følg disse trin:
        </p>

        <ul>
          <li>
            Besøg{" "}
            <a href="https://tagmanager.google.com" target="_blank" rel="noopener">
              tagmanager.google.com
            </a>{" "}
            og opret en konto.{" "}
          </li>{" "}
        </ul>
        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-create-account.png"
          alt="Google Tag Manager opret konto"
          className="article-img"
        />

        <ul>
          <li>
            Med kontoen opretter du en container til din hjemmeside, så sørg for at vælge <strong>Web</strong>{" "}
            til dens brug:
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/google-analytics-setup-via-google-tag-manager-container-creation.png"
          alt="Google Tag Manager web container oprettelses skærmbillede"
          className="article-img"
        />

        <p>
          Derefter vil du modtage <strong>Google Tag Manager kode snippet</strong> til implementering:
        </p>

        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-code-snippet.png"
          alt="Google Tag Manager kode snippet"
          className="article-img"
        />

        <p>
          Tilføj den første del til <code>&lt;head&gt; </code>
          taggen på alle sider på din hjemmeside. Den anden del tilføjes inde i <code>&lt;body&gt;</code> taggen.
        </p>
        <p>Efter Google Tag Manager (GTM) snippet er tilføjet, kan analytics opsætningen konfigureres på ingen tid!</p>

        <ul>
          <li>
            I GTM, klik på <strong>tags</strong> i venstre navigation og <strong>tilføj et nyt tag</strong>. En
            overlay vil glide ind fra siden, og du kan give taggen et <strong>navn</strong>, f.eks.{" "}
            <em>Google Analytics Tracking</em>.
          </li>
          <li>Klik derefter på tag-konfigurationsboksen:</li>

          <ImgScreenshot
            src="google-analytics-setup/google-tag-manager-create-new-tag.png"
            alt="Google Tag Manager opret nyt tag"
            className="article-img"
          />

          <li>
            En liste med tagtyper vil glide ind på skærmen. <br />
            Vælg <strong>Google Analytics - Universal Analytics</strong> taggen og start konfigurationen.
          </li>

          <ImgContainerFixed width="462px">
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-google-analytics-setup-tag-selection.png"
              alt="Google Tag Manager opret Google Analytics tag"
              className="article-img"
            />
          </ImgContainerFixed>

          <li>
            Hold <strong>Track Type</strong> til <strong>Pageview</strong>. <br />
          </li>
          <li>
            Opret derefter en ny Google Analytics indstillingsvariabel.
          </li>

          <ImgScreenshot
            src="google-analytics-setup/google-tag-manager-google-analytics-setup-settings.png"
            alt="Google Tag Manager indstillingsvariabel for Google Analytics skærmbillede"
            className="article-img"
          />

          <li>
            For at konfigurere indstillingsvariablen, giver du den først et <strong>navn</strong>. Derefter indsætter du{" "}
            <strong>Google Analytics Tracking ID</strong> (<em>UA-141112345-1</em>), som du{" "}
            <Link to="#where-to-get-the-google-analytics-tracking-code">modtog tidligere</Link>.
          </li>

          <ImgScreenshot
            src="google-analytics-setup/google-tag-manager-google-analytics-setup-tracking-id.png"
            alt="Google Tag Manager Google Analytics opsætning tracking id skærmbillede"
            className="article-img"
          />

          <li>
            Klik på <strong>save</strong>.
          </li>
          <li>
            Tilbage i tag-konfigurationen, klik på sektionen <strong>triggering</strong> for at vælge en trigger.
          </li>

          <li>
            Vælg <strong>all pages</strong>, fordi vi ønsker, at Google Analytics kode snippet skal køre på alle sider.
          </li>

          <li>
            <strong>Gem</strong> triggeren såvel som tag-konfigurationen og gå tilbage til Google Tag Manager oversigten.
          </li>

          <li>
            Klik på den blå <strong>submit</strong> knap i øverste højre hjørne. Giv din første container version et{" "}
            <strong>navn</strong> og klik på <strong>publish</strong>. <br />
            Nu har du med succes implementeret Google Analytics med Google Tag Manager!
          </li>
        </ul>

        <p>
          Du bør se data komme ind i din Google Analytics ejendom efter cirka en time eller deromkring.
          <br />
          Du kan også kigge på realtidsrapporten for at se, om der allerede kommer nogle data ind fra dit
          aktuelle besøg.
        </p>
        <ImgScreenshot
          src="google-analytics-setup/google-analytics-realtime-tracking-overview.png"
          alt="Google Analytics realtidsrapport oversigt"
        />

        <p>
          Læg mærke til, at du kunne implementere andre værktøjer eller scripts og trigge dem på <strong>alle sider</strong>, på bare få
          minutter? <br /> Jeg håber, dette får dig til at indse, hvor nemt og kraftfuldt et tag management system er.
        </p>

        <H as="h2">Hvordan opsætter man mål i Google Analytics?</H>
        <p>
          Nu hvor du modtager besøgsdata, er det tid til at opsætte <strong>Google Analytics mål</strong>.
        </p>
        <p>Chancerne er, at du sælger noget på din hjemmeside, eller der er en formular, som folk kan udfylde.</p>
        <p>
          Normalt lander brugerne på en <strong>takke-side</strong> efter en bestilling. Den siger typisk tak for bestillingen og lister nogle bestillingsdetaljer. <br /> Kig i adressefeltet og se, hvilken URL den side har.{" "}
        </p>
        <p>For eksempel kan den se sådan ud:</p>
        <p>
          <code>https://www.dinside.com/tak.php</code>{" "}
        </p>
        <p>
          Lad os opsætte et mål for denne adresse og tælle, hvor ofte denne side besøges for at indikere, hvor mange bestillinger der har fundet sted.
        </p>

        <ul>
          <li>
            Gå til Google Analytics <strong>admin område</strong> med en oversigt over din konto, ejendom og
            visning. Klik på <strong>goals</strong> inden for <strong>view</strong> sektionen.
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/google-analytics-admin-area-goals.png"
          alt="Google Analytics admin område mål"
          className="article-img"
        />

        <ul>
          <li>
            <strong>Tilføj</strong> et nyt mål og vælg <strong>custom</strong> i målopsætningen. Klik derefter{" "}
            <strong>continue</strong>.
          </li>
          <li>
            Giv dit mål et <strong>navn</strong>, for eksempel <em>Nyhedsbrev tilmelding</em> eller{" "}
            <em>Bestillingsformular modtaget</em> og vælg typen til at være en <strong>destination</strong>. Klik derefter{" "}
            <strong>continue</strong>.
          </li>
          <li>
            Under <strong>destination</strong> er <strong>equal to</strong>, tilføj URL <strong>path</strong>, så i
            vores eksempel:
            <p>
              <code>/tak.php</code>
            </p>
            Du kan også være smart og vælge <strong>Regular Expression</strong> som match type i dropdown
            menuen.
            <br />
            Hvis du ikke ved, hvad <strong>regular expressions</strong> er: De er en kraftfuld og praktisk måde at
            matche strenge på. Her kan du lære at bruge dem på en time eller to:{" "}
            <a href="https://regexone.com/" target="_blank" rel="noopener">
              RegexOne
            </a>
          </li>
          <li>
            Du kan valgfrit tildele en <strong>værdi</strong> til dit mål. Jeg vil anbefale det, hvis du kan tildele en
            numerisk værdi til hver af de takkesidevisninger.
            <br /> Der er flere måder at gøre dette på. Den nemmeste er at se på den månedlige indtægt fra de
            takkesider og dividere den med det samlede antal sidevisninger.
            <br />
            Det vil give dig gennemsnittet for hver gang målet rammes.
          </li>
          <li>
            <strong>Opsæt en funnel</strong> hvis brugerrejsen skal ramme nogle specifikke sider før
            konverteringen. Dette giver meget mening for formularer, der er opdelt i flere trin. Du kan tjekke
            senere, om en andel af de besøgende falder fra funnelen et sted under processen. <br />
            Du kan tilføje trin til funnelen ved at give hvert trin et <strong>navn</strong> og en{" "}
            <strong>side sti</strong> (<em>se nedenfor</em>). <br />
            Bemærk, at hvis du valgte <strong>equal to</strong> i dropdownmenuen øverst for destination, skal du bruge den
            samme match type for dine funnel regler.
            <br />
            Eller hvis du valgte <strong>regular expressions</strong>, skal du følge regex syntaksen i dine side
            match regler.
          </li>
          <li>
            Før du gemmer, klik på <strong>verify goal</strong>. <br />
            Hvis du allerede har nogle data i din GA ejendom, vil den køre igennem det og tjekke for matches i den
            historiske data. <br />
            Hvis du har nul matches, men ved der burde være nogle, har du sandsynligvis lavet en fejl i angivelsen af side
            stierne.
            <br /> Så det er et godt sted at dobbelt-tjekke, at reglerne virker, før du afslutter målopsætningen.
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/set-up-google-analytics-goals.png"
          alt="Google Analytics opsætning af mål skærmbillede"
          className="article-img"
        />

        <p>
          Tillykke! Du har lige opsat dit mål og kan spore målkonverteringer fremover! <br />
          Mål bør opsættes for de vigtigste konverteringer på din hjemmeside. Hvis andre konverteringer er mindre
          kritiske eller måske ikke er repræsenteret med deres egen side sti, kan du opsætte Google Analytics
          events.
          <br />
          Efter opsætning af en event kan du bruge den til at basere dine målkonverteringer på.
        </p>

        <H as="h2">Opsætning af events i Google Analytics</H>
        <p>
          Processen med <Link to="/da/event-sporing">opsætning af events i Google Analytics</Link> kan se meget
          forskellig ud for hver event. Afhængigt af interaktionen og hjemmesidens teknologi, er der forskellige trin
          at tage. Nedenfor finder du en liste over forskellige event tracking eksempler.
        </p>
        <H as="h3">Eksempler på event tracking</H>

        <ul>
          <li>dele dit indhold på sociale medier</li>
          <li>afspille videoen på din forside</li>
          <li>trykke på tilbageknappen til Google søge resultater</li>
          <li>scrolle ned til bunden af din artikel</li>
          <li>klikke på din kontakt email</li>
          <li>tilføje et produkt til indkøbskurven</li>
          <li>vælge en farvevariation af et produkt</li>
        </ul>

        <p>
          Proceduren for opsætning af scroll tracking er anderledes end for tracking af museklik. På dynamiske
          hjemmesider er HTML-elementer ikke altid synlige. Derfor vil click tracking kræve en anden tilgang
          end på statiske hjemmesider. <br />
          Pointen er, at der er mange påvirkende faktorer, så jeg skrev en separat artikel om event tracking. Der går jeg
          i detaljer, trin for trin, og forklarer, hvordan man sporer knapklik eller andre elementer. Se den her:
        </p>
        <p>
          <Link to="/da/event-sporing">Event tracking i Google Analytics</Link>
        </p>
        <H as="h2">Opsætning af kampagnesporing i Google Analytics</H>
        <p>
          Kampagnesporing er allerede indbygget i hver Google Analytics opsætning. Du skal bare tilføje <Link to="/da/wiki-analytics/utm-parameter">UTM parametre</Link> til
          linkene i dine email eller sociale mediekampagner.
        </p>
        <p>
          Der er fem parametre, som du kan tilføje til ethvert link, der peger på din hjemmeside. Den indbyggede Google
          Analytics kampagnesporing genkender en bruger fra et sådant link. Den tilskriver derefter besøget til den
          specificerede kampagne, og du kan finde det i kampagnerapporten.
        </p>
        <p>
          Lad os tage URL'en <code>https://www.dinside.com/kampagne</code>
          <br />
          og de følgende kampagnesporingsparametre som et eksempel:
        </p>

        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>UTM Parameter</th>
              <th>Parameter værdi</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>UTM Kilde</td>
              <td>Medium</td>
            </tr>
            <tr>
              <td>UTM Medium</td>
              <td>Gæsteindlæg</td>
            </tr>
            <tr>
              <td>UTM Kampagne</td>
              <td>Forårssalg</td>
            </tr>
            <tr>
              <td>UTM Term</td>
              <td>Støvler</td>
            </tr>
            <tr>
              <td>UTM Indhold</td>
              <td>Støvler når det er varmt udenfor</td>
            </tr>
          </tbody>
        </table>

        <p>
          Dette er den <strong>samme URL inklusive UTM parametre</strong>:
        </p>
        <p>
          <code>
            https://www.yoursite.com/campaign?<strong>UTM_source</strong>
            =medium&amp;<strong>UTM_medium</strong>
            =guest-post&amp;<strong>UTM_campaign</strong>=spring-sales&amp;
            <strong>UTM_term</strong>=boots&amp;<strong>UTM_content</strong>
            =boots-when-its-warm-outside
          </code>
        </p>
        <p>
          Du kan bruge det på sociale medier eller på andre kilder, der linker til din side. Google Analytics sporer
          automatisk, hvor mange klik der når dig fra sådan et link.
        </p>
        <p>
          Bemærk de <strong>fem UTM parametre</strong> i URL'en? Du behøver ikke tilføje dem manuelt. Der er{" "}
          <a
            href="https://ga-dev-tools.appspot.com/campaign-URL-builder/"
            target="_blank"
            title="Værktøj til at bygge links med UTM parametre"
          >
            UTM Builder værktøjer
          </a>{" "}
          der gør det for dig. Du giver dem bare <strong>linket til din hjemmeside</strong> og værdierne for de{" "}
          <strong>UTM parametre</strong>. Derefter vil de give dig kampagnesporingslinket, inklusive alle UTM
          parametre, tilbage.
        </p>

        <H as="h2">Google Analytics Bedste Fremgangsmåder</H>
        <p>
          Nedenfor er en liste over yderligere <strong>bedste fremgangsmåder</strong> for opsætning af Google Analytics. Jeg anvender hver enkelt omhyggeligt
          for hver GA opsætning, jeg konfigurerer. De er ikke blot <em>nice to have</em>, men snarere nødvendige for at maksimere
          datakvaliteten. <br />
          Filtre kan også opsættes i tag management systemet. Faktisk foretrækker jeg det, fordi JavaScript tillader mere
          avancerede regler ved filtrering. Ikke desto mindre er filtre i Google Analytics enklere og klarer opgaven næsten lige så godt.
        </p>
        <ol start="1">
          <li>
            <H as="h3">Ekskluder egen trafik fra Google Analytics</H>
            Nu hvor du sporer besøg, skal du ekskludere dine egne sessioner fra din webtrafik. Din browseradfærd vil afvige fra almindelige brugere og ellers forvride dine data. Dette gælder især for sider med lav trafik, da egne sidevisninger vejer tungere i det samlede billede.
            <br />
            For at <strong>ekskludere din egen trafik</strong> kan du filtrere dig selv fra ved hjælp af <strong>IP-adresse</strong>,
            hvis du har en statisk IP.
            <br />
            Hvis ikke, kan du overveje at få en fra din internetudbyder, da de normalt ikke koster meget.
          </li>
        </ol>
        <ul>
          <li>
            For at <strong>opsætte IP-ekskludering i Google Analytics</strong>, gå til <strong>Admin panel</strong> og
            klik på <strong>Filters</strong> i <strong>view</strong> kolonnen til højre.
          </li>
          <li>
            Tilføj et <strong>navn</strong> til dit filter og vælg <strong>filter type</strong> til at være{" "}
            <strong>custom</strong>.
          </li>
          <li>
            Vælg <strong>exclude</strong> filtermuligheden og vælg <strong>IP Adresse</strong> i dropdown menuen.
          </li>
          <li>
            Indtast derefter din statiske IP i <strong>filter pattern</strong> feltet og klik på <strong>save</strong>.
          </li>
        </ul>

        <ImgContainerFixed width="415px">
          <ImgScreenshot
            src="google-analytics-setup/exclude-ip-from-google-analytics-setup.png"
            alt="Ekskluder IP fra Google Analytics opsætning skærmbillede"
            className="article-img"
          />
        </ImgContainerFixed>

        <ol start="2">
          <li>
            <H as="h3">Transformer URL-stier til små bogstaver</H>
            En anden justering er automatisk at <strong>transformere dine URL-stier til små bogstaver</strong>.
            <br />
            Mennesker laver fejl, og på et tidspunkt vil nogen oprette en URL med store bogstaver i navnet. Hvis
            hjemmesiden så returnerer det samme indhold som for den samme URL med små bogstaver, bliver det rodet.{" "}
            <br /> Lad mig forklare. <br />
            Vi sender essentielt data under to forskellige URL-stier, selvom dataene er for den samme side.
            <br />
            URL-stier skrevet med varierende store og små bogstaver fører til rodet analyse i Google Analytics. Enhver unik skrivning af den
            samme sti vil blive vist på sin egen række i rapporterne. Efter et stykke tid, vil du ende med at se på flere rækker
            og deres metrics, selvom de burde være kombineret. <br />
            Hvis du så også får backlinks til den samme side, men adressen i linket blev skrevet på en sjov måde,
            er rodet perfekt. Så lad os forsøge at holde vores liv drama-frit og løse dette på forhånd.
          </li>
        </ol>
        <ul>
          <li>
            For at <strong>transformere URL-stier til små bogstaver</strong> besøg <strong>Filters</strong> for din view
            igen og tilføj et nyt filter. Giv det et navn og vælg <strong>custom</strong> filter type.
          </li>
          <li>
            Vælg nu <strong>Lowercase</strong> og vælg <strong>Request URI</strong> i feltets dropdown.
          </li>
        </ul>

        <ImgContainerFixed width="369px">
          <ImgScreenshot
            src="google-analytics-setup/set-up-lowercase-urls-in-google-analytics.png"
            alt="Opsæt små bogstaver URL'er i Google Analytics"
            className="article-img"
          />
        </ImgContainerFixed><br />

        <ul>
          <li>
            Klik på <strong>save</strong> for at afslutte. Let løsning, der sparer mange hovedpiner.
          </li>
        </ul>
        <ol start="3">
          <li>
            <H as="h3">Google Analytics View Struktur</H>
            <p>
              En <strong>view</strong> er hvor du får adgang til alle dine Google Analytics rapporter. Hvis der er filtre opsat
              for din view, vil alle rapporter blive filtreret derefter.
              <br />
              Din <strong>view struktur</strong> bør normalt afspejle din organisations struktur. Hvis dine målmarkeder f.eks. er mere regionale end landbaserede, opsæt dine views på samme måde. Det vil være praktisk, når KPI'er for regioner er direkte tilgængelige.
              <br />
              En anden orientering er din organisations struktur for budgettering og marketingindsats. Hvis det gøres
              på landniveau, er det sandsynligvis en god idé at følge den struktur for views også.
              <br />
              Du kan oprette op til 25 views pr. ejendom. Så afhængigt af antallet af markeder og marketingkanaler er flere view strukturer mulige.
            </p>
            <H as="h3">Eksempel: Google Analytics view struktur for lokale hjemmesider</H>
            <p>
              1.0 Master
              <br />
              2.0 Intern trafik
              <br />
              3.0 Rå data
            </p>
            <p>
              På den ene eller anden måde er der normalt 3 typer views. De kunne filtreres yderligere efter land
              eller enhed, hvis det giver mening for din organisation.
            </p>
            <H as="h3">Eksempel: Google Analytics view struktur for multinationale hjemmesider</H>
            <p>
              1.1 Master (US)
              <br />
              1.2 Master (UK)
              <br />
              1.3 Master (DE)
              <br />
              1.4 Master (FR)
              <br />
              1.5 Master (ES)
              <br />
              1.6 Master (MX)
              <br />
              1.7 Master (RUS)
              <br />
              2.0 Intern Trafik
              <br />
              3.0 Rå Data
            </p>

            <H as="h3">Eksempel: Google Analytics regional view struktur</H>
            <p>
              1.0 Master (NA)
              <br />
              1.1 Master (EMEA)
              <br />
              1.2 Master (LATAM)
              <br />
              1.3 Master (APEC)
              <br />
              2.0 Intern trafik
              <br />
              3.0 Rå data
            </p>

            <p>
              <strong>Master view</strong> er den primære view til analyse og ekskluderer al intern trafik.
            </p>
            <p>
              <strong>Intern trafik view</strong> inkluderer al intern trafik. Denne kan også bruges til
              fejlfinding og testformål. Hvis du indstiller inkluderingsfilteret, vil alt andet blive ekskluderet
              automatisk.
            </p>
            <p>
              I begyndelsen opretter Google Analytics en første <strong>All Web Site Data view</strong> sammen
              med GA ejendommen.
            </p>
            <p>
              Behold den, men ændr navnet til <strong>Rå data</strong>. Du vil have en rå data view uden nogen
              filtre. Du kan ikke fortryde et filter retroaktivt og se de ufiltrerede data. Derfor er det en bedste
              praksis at holde en view uberørt. For at ændre navnet, gå til{" "}
              <strong>Admin &gt; View &gt; View Settings</strong>.
            </p>
            <p>
              I de fleste tilfælde fokuserer virksomheder deres indsats på et eller få lande. I så fald skal du oprette
              views på landebasis og indstille filtre derefter.
              <br />
              Du kan også oprette views pr. trafikkilde (organisk, betalt, direkte, social, etc.) eller enhed (desktop,
              tablet, mobil). Jeg bruger Google Analytics segmenter til det, men der er ingen grund til ikke at filtrere
              de specifikke allerede i viewet. Jeg kan godt lide at holde antallet af views lavt, fordi det holder GA
              brugergrænsefladen ren.
              <br />Jeg kan så blive lidt mere rodet med segmenter i viewet og anvende resterende specifikationer der.
            </p>
            <p>
              Opret alle views så tidligt som muligt, fordi de kun viser data fra det øjeblik, de blev oprettet. En
              ny view vil ikke vise nogen historiske data fra før oprettelsesdatoen. Hvis du har brug for at se tilbage i
              historien med specifikke filtre, kan du falde tilbage på rå data viewet. Du anvender blot de tilsigtede filtre
              med segmenter i stedet. På denne måde får du din indsigt, men ændrer ikke dataene.
            </p>
            <p>
              <strong>TIP</strong>: Hvis nogle views deler de samme filtre, kan du oprette en og derefter duplikere den
              sammen med alle filtre. Derefter tilføjer du blot de view-specifikke filtre til duplikaterne.
              <br />
              Sørg for at holde den oprindelige view uændret, så du kun tilføjer filtre til duplikaterne. Hvis du ændrer den oprindelige view senere, vil det påvirke de duplikerede views også.
            </p>
          </li>
          <li>
            <H as="h3">Google Analytics Alerts</H>
            En anden bedste praksis er at <strong>opsætte Google Analytics alerts</strong>. Jeg sætter det op til at blive
            underrettet om fald i sessioner eller når serverens svartid stiger. På denne måde kan jeg undersøge årsagerne
            og potentielt gøre noget ved det.
          </li>
        </ol>
        <ul>
          <li>
            For at opsætte en alert i Google Analytics, besøg <strong>Customization</strong> i sidemenuen.
          </li>
          <li>
            Alternativt kan du også besøge <strong>Admin</strong> området igen og klikke på{" "}
            <strong>Custom Alerts</strong> i view panelet.
          </li>
          <li>
            Klik på <strong>Custom Alerts</strong> og klik på <strong>manage customized alerts</strong>.
          </li>
          <li>
            Konfiguration af en Google Analytics alert for faldende sessioner er ret simpelt. Klik på{" "}
            <strong>New Alert</strong> og navngiv alerten for eksempel <strong>Session decrease</strong> med{" "}
            <strong>Period</strong> sat til <strong>Week</strong>.
          </li>
          <li>Tjek også boksen for at modtage en e-mail, når alerten udløses.</li>{" "}
          <li>
            Der er normalt trafikudsving mellem hverdage, weekender og nationale helligdage. Vi ønsker ikke at
            blive alarmeret over disse udsving, så vi indstiller den til at se på ugentlige sessionsdata.{" "}
          </li>
          <li>
            <strong>Alert Conditions</strong> gælder for <strong>al trafik</strong>, og du vil blive alarmeret
            når <strong>sessioner falder med mere end 35% i forhold til den foregående uge.</strong>
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/set-up-google-analytics-alert-session-decrease.png"
          alt="opsæt Google Analytics alerts for faldende sessioner skærmbillede"
          className="article-img"
        />

        <p>
          Derefter klik på <strong>save</strong> og lad os tilføje den næste alert.
        </p>
        <p>For at opsætte en Google Analytics alert for stigende server svartid,</p>
        <ul>
          <li>
            klik på <strong>New Alert</strong> og giv den et navn, som{" "}
            <em>Gns. server svartid stiger dag til dag</em> og
          </li>
          <li>
            vælg <strong>dag</strong> som perioden.
          </li>
          <li>
            For <strong>alert conditions</strong>, gælder det for <strong>al trafik</strong> og du vil blive
            alarmeret, når din <strong>gns. server svartid stiger med mere end 300%</strong> i forhold til{" "}
            <strong>dagen før</strong>:
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/set-up-alerts-server-rsp-time-google-analytics.png"
          alt="opsæt Google Analytics alerts for server svartid skærmbillede"
          className="article-img"
        />

        <p>
          Derefter klik på <strong>save</strong>.<br />
          Nu kan du være sikker på at modtage en alert e-mail, når din trafik falder, eller når din server har
          problemer.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default AnalyticsSetup;

